exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-end-user-license-agreement-js": () => import("./../../../src/pages/end-user-license-agreement.js" /* webpackChunkName: "component---src-pages-end-user-license-agreement-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policies-js": () => import("./../../../src/pages/privacy-policies.js" /* webpackChunkName: "component---src-pages-privacy-policies-js" */),
  "component---src-pages-pronunciation-guide-js": () => import("./../../../src/pages/pronunciation-guide.js" /* webpackChunkName: "component---src-pages-pronunciation-guide-js" */),
  "component---src-pages-pronunciation-js": () => import("./../../../src/pages/pronunciation.js" /* webpackChunkName: "component---src-pages-pronunciation-js" */),
  "component---src-pages-stores-js": () => import("./../../../src/pages/stores.js" /* webpackChunkName: "component---src-pages-stores-js" */),
  "component---src-pages-term-of-use-js": () => import("./../../../src/pages/term-of-use.js" /* webpackChunkName: "component---src-pages-term-of-use-js" */),
  "component---src-pages-vocabularies-js": () => import("./../../../src/pages/vocabularies.js" /* webpackChunkName: "component---src-pages-vocabularies-js" */)
}

